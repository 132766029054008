import React from 'react';
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import RouteManager from './routes/RouteManager';
import AppContextProvider from './context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { IApp } from './context/models';
import { MsalProvider } from '@azure/msal-react';



console.log('App.tsx ~~~~  REACT_APP_FGI_TEST_VARIABLE', process.env.REACT_APP_FGI_TEST_VARIABLE);
console.log('App.tsx ~~~~  REACT_APP_FGI_TEST_VARIABLE_2', process.env.REACT_APP_FGI_TEST_VARIABLE_2);
console.log('App.tsx ~~~~  xyz', process.env.xyz);
console.log('App.tsx ~~~~  REACT_APP_FGI_API_CLIENT_URL', process.env.REACT_APP_FGI_API_CLIENT_URL);

function App({ instance }: IApp) {
  const accounts = instance.getAllAccounts();
  console.log('msal instance' , instance);
  const nameOfUser = localStorage.getItem('nameOfUser');
  return (
    <MsalProvider instance={instance}>
      <div className="App" data-testid="appTestId">
        <BrowserRouter>
          <AppContextProvider>
            <Header user={{ name: nameOfUser as string }} />
            <RouteManager />
            <Footer />
          </AppContextProvider>
        </BrowserRouter>
      </div>
    </MsalProvider>
  );
}

export default App;
